export class TransitPoint {
    id: string | undefined;
    name: string;
    description: string;
    licensePlate: string;
    phone: string;
    isActive: boolean;

    constructor(name: string = '') {
        this.name = name;
        this.description = '';
        this.licensePlate = '';
        this.phone = '';
        this.isActive = true;
    }
}
