import axios from 'axios';
import {TransitPoint} from '@/models/category/TransitPoint';
import {SearchResult} from "@/models/SearchResult";
import {ActionResult} from "@/models/ActionResult";
import {RouteResult} from "@/store/category/RouteService";

export class OptionItem {
    id: string;
    name: string;

    constructor(id: string, name: string)
    {
        this.id = id;
        this.name = name;
    }
}

export class TransitPointService {
    url = `${process.env.VUE_APP_CORE_URL}api/v1/transitPoints`;

    search(keyword: string, page: number, pageSize: number):
        Promise<SearchResult<TransitPoint>> {
        return axios.get(this.url, {
            params: {
                keyword: keyword,
                page: page,
                pageSize: pageSize
            }
        });
    }

    autoSugesst(keyword: string):
        Promise<SearchResult<RouteResult>>{
        return axios.get(`${this.url}/auto-sugesst`, {
            params: {
                id : "",
                keyword : keyword,
                pageSize : 100000
            }
        });
    }

    insert(item: TransitPoint):
        Promise<ActionResult> {
        return axios.post(this.url, item);
    }

    update(item: TransitPoint):
        Promise<ActionResult> {
        return axios.post(`${this.url}/update`, item);
    }

    delete(id: string):
        Promise<ActionResult> {
        return axios.delete(`${this.url}/${id}`);
    }

    changeActive(id: string, isActive: boolean):
        Promise<ActionResult> {
        return axios.post(`${this.url}/change-active`, {
            id: id,
            isActive: isActive
        });
    }
}