


































































































































import {Component, Vue, Watch} from 'vue-property-decorator';
import {required} from 'vee-validate/dist/rules';
import {ValidationProvider, ValidationObserver, extend} from 'vee-validate';
import {TransitPointService} from '../../store/category/TransitPointService';
import {TransitPoint} from '@/models/category/TransitPoint';
import vSelect from 'vue-select'
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import i18n from "@/i18n";
import TransitPointSelect from "@/components/category/TransitPointSelect.vue";

extend('required', {
    ...required,
    message: (_: string, values: any) => i18n.t('validations.required', [_])
} as any);

@Component({
    components: {
        vSelect,
        ValidationProvider,
        ValidationObserver,
        TransitPointSelect
    }
})

export default class TransitPointPage extends Vue {
    currentPage: number = 1;
    rows: any = [];
    totalPages = 0;
    pageSize = 10;
    activePrompt: Boolean = false;
    isAdd: Boolean = false;
    keyword: string = '';
    selected: any = [];
    private transitPointService: TransitPointService;
    backgroundLoading: string = 'primary';
    colorLoading: string = '#fff';
    totalRows: number = 0;
    inventories: any = [];
    transitPoint: TransitPoint = new TransitPoint();
    invalid: any = false;
    currentRow: any = null;
    debounce: any = null;
    id: string = "";

    constructor() {
        super();
        this.transitPointService = new TransitPointService();
    }

    beforeMount() {
    }

    mounted() {
        this.search();
    }

    async setPageSize(size: number) {
        this.pageSize = size;
        this.search();
    }

    @Watch('keyword')
    onKeyWordInputChange(value: string, oldValue: string) {
        if (this.debounce) {
            this.debounce.cancel();
        }

        this.debounce = debounce(() => this.search(), 500);
        this.debounce();
    }

    @Watch('currentPage')
    onCurrentPageChange(value: string, oldValue: string) {
        this.search();
    }

    showAddForm() {
        this.activePrompt = true;
        this.isAdd = true;
    }

    setUpdate(item: TransitPoint) {
        this.isAdd = false;
        this.transitPoint = new TransitPoint();
        this.transitPoint.id = item.id;
        this.transitPoint.name = item.name;
        this.transitPoint.description = item.description;
        this.transitPoint.licensePlate = item.licensePlate;
        this.transitPoint.phone = item.phone;
        this.activePrompt = true;
        this.currentRow = item;
    }

    async changeActive(item: any) {
        const result = await this.transitPointService.changeActive(item.id, !item.isActive);

        if (result.code > 0) {
            this.$vs.notify({
                title: "Thông báo",
                text: "Lưu thành công",
                color: 'primary',
                iconPack: 'feather',
                icon: 'icon-info'
            });
        } else {
            console.log(result);
        }
    }

    openConfirm(item: any) {
        this.$vs.dialog({
            type: 'confirm',
            color: 'danger',
            title: `Xác nhận`,
            text: `Bạn có chắc chắn muốn xóa điểm trung chuyển "${item.name}" không?`,
            accept: this.delete
        })

        this.currentRow = item;
    }

    async delete() {
        let id = this.currentRow.id;
        const result = await this.transitPointService.delete(id);

        this.$vs.notify({
            title: "Thông báo", text: result.message, color: (result.code > 0 ? 'primary' : 'danger'),
            iconPack: 'feather', icon: 'icon-info'
        });

        this.rows = filter(this.rows, function (obj) {
            return obj.id != id;
        });
    }

    async save() {
        this.$vs.loading({
            background: this.backgroundLoading,
            color: this.colorLoading,
            container: "#btnSave",
            scale: 0.45
        })

        this.invalid = false;

        if (this.isAdd) {
            const result = await this.transitPointService.insert(this.transitPoint);

            this.$vs.notify({ title: "Thông báo", text: result.message, color: (result.code > 0?'primary':'danger'),
                iconPack: 'feather', icon:'icon-info'});

            if (result.code > 0) {
                this.search();
                this.activePrompt = false;
            }
        } else {
            const result = await this.transitPointService.update(this.transitPoint);

            this.$vs.notify({ title: "Thông báo", text: result.message, color: (result.code > 0?'primary':'danger'),
                iconPack: 'feather', icon:'icon-info'});

            if (result.code > 0) {
                this.activePrompt = false;
                this.currentRow.name = this.transitPoint.name;
                this.currentRow.description = this.transitPoint.description;
                this.currentRow.licensePlate = this.transitPoint.licensePlate;
                this.currentRow.phone = this.transitPoint.phone;
            }
        }
        setTimeout(() => {
            this.$vs.loading.close("#btnSave > .con-vs-loading")
        }, 500);
    }

    openLoading() {
        this.$vs.loading()
    }

    beginSearch() {
        if (this.keyword == undefined || this.keyword.length < 2) {
            return;
        }

        this.search();
    }

    private async search() {
        this.openLoading();
        const result = await this.transitPointService.search(this.keyword, this.currentPage, this.pageSize);

        if (result.code > 0) {
            this.rows = result.items;
            this.setTotalPage(result.totalRows);
        }

        setTimeout(() => {
            this.$vs.loading.close()
        }, 500);
    }

    private setTotalPage(totalRows: number) {
        this.totalRows = totalRows;

        if (totalRows == 0) {
            this.totalPages = 0;
        }
        if (totalRows <= this.pageSize) {
            this.totalPages = 1;
        }
        this.totalPages = Math.ceil((totalRows / this.pageSize));
    }
}
