




















import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {SearchResult} from "@/models/SearchResult";
import vSelect from 'vue-select';
import {TransitPointService, OptionItem} from '@/store/category/TransitPointService';
import * as _ from 'lodash';
import { TransitPoint } from '@/models/category/TransitPoint';

@Component({
    components: {
        vSelect
    }
})

export default class TransitPointSelect extends Vue {
    @Prop() value!: any;
    @Prop() text!: any;
    @Prop({default: 'Nhập nội dung cần tìm'}) noFoundText!: string;
    @Prop({default: true}) allowAdd!: boolean;
    service = new TransitPointService();
    data: Array<OptionItem> = new Array<OptionItem>();
    selected: any = null;

    mounted() {
    }

    onSearch(search: string, loading: any) {
        loading(true);

        this.searchDebounce(loading, search, this);
    }

    @Watch('selected')
    onSelectedChange(value: any, oldValue: any){
        this.$emit('input', value);
        this.$emit('change', value);
    }

    searchDebounce: any = _.debounce((loading: any, search: string, vm: any) => {
        if(search.length == 0)
        {
            loading(false);
            return;
        }

        this.service.autoSugesst(search)
            .then((result: SearchResult<OptionItem>) => {
                loading(false);

                if(result.items.length == 0)
                {
                    vm.data = [new OptionItem("-1", search)];
                }
                else
                {
                    vm.data = result.items;
                }
            })
            .catch(e => loading(false));
    }, 500);

    // handlers = (map: any, vm: any) => {
    //     return {
    //         ...map,
    //         13: (e: any) => {
    //              //e.preventDefault();
    //             // debugger;
    //             if (vm.options.length === 1 && vm.options[0].id == -1) {
    //                 this.addNewRoute(vm);
    //             }
    //         }
    //     };
    // };

    private addNew(option: any) {

        if (!option.name || option.name.length <= 0) {
            return;
        }

        this.service.insert(new TransitPoint(option.name))
            .then((result: any) => {
                if (result.code > 0) {
                    this.data[0].id = result.title;
                    this.data[0].name = option.name;
                    this.selected =  result.title;
                }
            });
    }

    // private search(searchTerm: string) {
    //     this.routeService.autoSugesst(searchTerm)
    //         .then((result: SearchResult<RouteResult>) => {
    //             this.data = result.items;
    //         });
    // }
}
